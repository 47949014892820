<template>
    <Dialog
        class="adicionar-area-atuacao"
        :header="`${ model.id ? 'Editar' : 'Adicionar' } área de atuação`"
        :visible.sync='show' :modal='true'
        :contentStyle="{overflowY: 'auto !important'}"
    >
        <div class="p-grid">
            <div class="p-col-12 p-fluid" :class="{ 'form-group--error': submitted && $v.model.ds_area_atuacao.$error }">
                <!-- p {{ model }} -->
                <div class="campo-descricao">
                    <label class="form-label">Descrição:</label>
                    <InputText v-model="$v.model.ds_area_atuacao.$model" />
                    <div class="feedback--errors" v-if='submitted && $v.model.ds_area_atuacao.$error'>
                        <div class="form-message--error" v-if="!$v.model.ds_area_atuacao.minLength">
                            Deve ter pelo menos 2 caracteres.
                        </div>
                        <div class="form-message--error" v-if="!$v.model.ds_area_atuacao.required">
                            Campo obrigatório.
                        </div>
                    </div>
                </div>
                <label class="form-label">Tipo de Área de Atuação:</label>
                <ProgressSpinner v-if="waitingDominio" class="waiting-add" />
                <div v-else>
                    <Dropdown
                        appendTo="body"
                        v-model='model.ie_tipo_area_atuacao'
                        :options='options.tipo_area_atuacao'
                        optionLabel='text'
                        optionValue='value'
                        placeholder='Selecione o tipo de área de atuação'
                    />
                </div>

            </div>

            <div class="p-col-12 ta-right">
                <ProgressSpinner class="waiting-add" v-if="waiting" strokeWidth="6" />
                <Button v-else label="Salvar" icon="jam jam-check" @click="save()" />
            </div>
        </div>
    </Dialog>
</template>

<script>
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import ProgressSpinner from 'primevue/progressspinner';
import Dropdown from 'primevue/dropdown';

import {minLength, required} from "vuelidate/lib/validators";
import {AreaAtuacao} from "@/middleware";
import { DominioValor } from '../../middleware';

export default {
    components: { Button, Dialog, InputText, ProgressSpinner, Dropdown },
    props: ['visible', 'model'],
    computed: {
        show: {
            get() { return this.visible },
            set(value) { if (!value) this.$emit('close') }
        }
    },
    created() {
        this.getDominio()
    },
    data() {
        return {
            submitted: false,
            waiting: false,
            waitingDominio: false,
            ie_tipo_area_atuacao: null,
            options: {
                tipo_area_atuacao: [],
            },
        }
    },
    validations () {
        return {
            model: {
                ds_area_atuacao: { required, minLength: minLength(2) },
            }
        }
    },
    methods: {
        save() {
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) return 0;

            let dataSend = Object.assign({}, this.model);

            this.waiting = true
            AreaAtuacao.save(dataSend).then(response => {
                this.waiting = false
                if ([200, 201].includes(response.status)) {
                    this.$toast.success('Salvo com sucesso.', { duration: 3000 });
                    this.$emit('saved');
                    this.submitted = false;
                    this.show = false;
                } else if ([400, 404].includes(response.status)) {
                    if (response.data.non_field_errors) {
                        response.data.non_field_errors.forEach(errorMsg => {
                            this.$toast.error(errorMsg, { duration: 3000 });
                        })
                    } else if (response.data.detail && typeof response.data.detail == 'string') {
                        this.$toast.error(response.data.detail, { duration: 3000 });
                    }
                }
            })
        },
        getDominio() {
            this.waitingDominio = true
            DominioValor.findAll({ds_mnemonico: 'TIPO_AREA_ATUACAO'}).then((response) => {
                this.options.tipo_area_atuacao.push({value: '', text: 'NENHUMA'})
                if(response.status === 200) {
                    response.data['TIPO_AREA_ATUACAO'].valores.forEach(item => {
                        this.options.tipo_area_atuacao.push({value: item.ie_valor, text: item.ds_valor})
                    })
                }
                this.waitingDominio = false
            })
        },
    }
}
</script>

<style lang="scss">
.adicionar-area-atuacao {
    width: 96%;
    max-width: 500px;
    
    .waiting-add {
        margin-right: 10px;
        width: 25px;
        height: auto;
    }
    .campo-descricao{
        margin-bottom:7px;
    }
    .input-switch-custom {
        margin-top: 2px;
    }
}

.p-dropdown-panel {
    z-index: 2010;
}
</style>
