<template lang="pug">
    .main-wrapper.unidades-listar
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-12.ta-right
                    h1.text-header.text-secondary MedClub / <b>Área de Atuação</b>

        Dialog.dialogApagar(header='Remover Empresa' :visible.sync='dialogApagar' :modal='true')
            p Deseja remover a área de atuação <b>{{ dialogApagar_data.ds_area_atuacao }}</b>?
            .ta-center.my-4
                ProgressSpinner(v-if='waitingApagar' strokeWidth='6')
                Button.p-button-danger(v-else label='Remover' @click='remove()')

        DialogSalvar(
            :visible="dialogSalvar.visible"
            :model="dialogSalvar.model"
            @close="onCloseDialogSalvar()"
            @saved="applyFilters(1, true)")

        Panel.my-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576' mode="indeterminate")
            .p-grid.p-fluid
                .p-col-12.p-md-9
                    label.form-label Área de Atuação:
                    .p-inputgroup
                        InputText(placeholder='Área de Atuação'
                            @keyup.enter.native='() => !waiting && applyFilters()'
                            @input='(val) => !val && applyFilters()'
                            v-model='filters.ds_area_atuacao'
                        )
                        Button(icon='jam jam-search' @click='applyFilters()' :disabled='waiting')
                .p-col-12.p-md-3
                    label.form-label Tipo de Área de Atuação:
                    ProgressBar(v-if="waitingDominio" mode="indeterminate")
                    .p-inputgroup(v-else)
                        MultiSelect(
                            v-model='filters.tipo_area_atuacao'
                            :options='options.tipo_area_atuacao'
                            optionLabel='text'
                            optionValue='value'
                            placeholder='- TODAS -'
                            @change='applyFilters()' 
                        )

        .ta-right.my-2
            Button(label='Adicionar' icon="jam jam-plus" @click="dialogSalvar.visible = true")

        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else-if='list.length == 0')
            p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
        div(v-else)
            DataView.dataview.my-2(:value="list" layout="grid")
                template(#grid="props")
                    .p-col-12.p-md-3(style="padding: .5em" :class="{ disabled: !props.data.ie_status }")
                        Panel.panel-list.ta-center(:header="props.data.nm_fantasia" style='position: relative')
                            .ta-left
                                p <b>Área de Atuação:</b> {{ props.data.ds_area_atuacao }}

            Paginator.dataview(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

            Panel.datatable(header='Lista de Área de Atuação')
                Paginator.mb-1(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
                DataTable(:value="list")

                    Column(headerStyle='width: 3%;' field='id' header='Código')
                        template(#body='props')
                            span(v-tooltip.top="props.data.id" style='cursor: pointer;') {{ props.data.id }}

                    Column(headerStyle='width: 30%;' field='ds_area_atuacao' header='Área de Atuação')
                        template(#body='props')
                            span(v-tooltip.top="props.data.ds_area_atuacao" style='cursor: pointer;') {{ props.data.ds_area_atuacao }}

                    Column(headerStyle='width: 8%; text-align: center')
                        template(#header) Ações
                        template(#body='props')
                            .ta-center
                                Button.p-button-raised.p-button-rounded.mr-1(
                                    v-tooltip.top="'Editar'"
                                    icon="jam jam-write"
                                    @click="onEditClick(props.data)"
                                )
                                Button.p-button-raised.p-button-rounded.p-button-danger(
                                    v-tooltip.top="'Apagar'"
                                    icon="jam jam-minus-circle"
                                    @click="dialogApagar = true; dialogApagar_data = props.data"
                                )
                Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
</template>

<style lang="scss">
    .unidades-listar {
        .dataview {
            @media all and (min-width: 577px) {
                display: none;
            }
        }
        .datatable {
            @media all and (max-width: 576px) {
                display: none;
            }
            .cell {
                padding: 16px 0;
                text-align: center;
                &.ex  {  background-color: #e4f8e1; }
                &.a  { background-color: #faf3dd; }
            }
        }
        .dialogApagar {
            .p-progress-spinner {
                width: 29px;
                height: auto;
            }
        }
    }
    .p-tooltip {
        max-width: none;
    }
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import DataView from 'primevue/dataview'
    import Panel from 'primevue/panel'
    import Paginator from 'primevue/paginator'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import Button from 'primevue/button'
    import Tooltip from 'primevue/tooltip'
    import Dialog from 'primevue/dialog'
    import InputText from 'primevue/inputtext'
    import ProgressSpinner from 'primevue/progressspinner'

    import { AreaAtuacao, DominioValor } from './../../middleware'
    import wsConfigs from './../../middleware/configs'
    import axios from "axios"
    import DialogSalvar from "@/components/AreaAtuacao/DialogSalvar"
    import MultiSelect from 'primevue/multiselect'

    const _ = require('lodash')

    export default {
        created () { 
            this.applyFilters() 
            this.getDominio()
        },
        components: {
            DialogSalvar, ProgressBar, DataView, Panel, Paginator, DataTable,
            Column, Button, Tooltip, Dialog, ProgressSpinner, InputText, MultiSelect },
        directives: { tooltip: Tooltip },
        data () {
            return {
                list: [],
                windowInnerWidth: window.innerWidth,
                waiting: false,
                waitingApagar: false,
                waitingDominio: false,
                dialogApagar: false,
                dialogApagar_data: {},
                cancelToken: null,
                params: {},
                getListDebounce: _.debounce(function (params, getFunction) {
                    getFunction(params)
                }, 300, { leading: true }),
                filters: {
                    ds_area_atuacao: '',
                    tipo_area_atuacao: null
                },
                paginator: {
                    page: this.$route.query.pg ? this.$route.query.pg : 1,
                    per_page: wsConfigs.paginator_perPage,
                    count: 0
                },
                dialogSalvar: {
                    model: { ds_area_atuacao: '', ie_tipo_odonto: false },
                    visible: false
                },
                options: {
                    tipo_area_atuacao: [],
                }
            }
        },
        methods: {
            onEditClick(model) {
                this.dialogSalvar.model = Object.assign({}, model)
                this.dialogSalvar.visible = true
            },
            onCloseDialogSalvar() {
                this.dialogSalvar.visible = false
                this.dialogSalvar.model = {}
            },
            checkEmpty_nm_fantasia () {
                if (! this.filters.nm_fantasia) this.applyFilters()
            },
            getList (params) {
                if (this.cancelToken) this.cancelToken.cancel()
                this.cancelToken = axios.CancelToken.source()

                this.waiting = true
                return AreaAtuacao.findAll(params, this.cancelToken.token).then(response => {
                    if (!response) return
                    if (response.status === 200) {
                        this.paginator.count = response.data.count
                        this.list = response.data.results
                    }
                    this.waiting = false
                    this.cancelToken = null
                })
            },
            applyFilters(page, force) {
                this.paginator.page = page || 1
                let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }
                if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
                Object.keys(this.filters).forEach((key) => { if (this.filters[key]) params[key] = this.filters[key]  })

                // Fake loading quando os filtros forem os mesmos
                if (_.isEqual(this.params, params) && !force) {
                    if (!this.waiting) {
                        this.waiting = true
                        setTimeout(() => this.waiting = false, 300)
                    }
                } else {
                    this.params = Object.assign({}, params)
                    this.waiting = true
                    this.getListDebounce(params, this.getList)
                }
            },
            onPage (ev) {
                this.applyFilters(ev.page + 1)
            },
            remove () {
                this.waitingApagar = true
                AreaAtuacao.remove(this.dialogApagar_data.id).then(response => {
                    this.waitingApagar = false
                    if (([200, 201, 204]).includes(response.status)) {
                        this.$toast.info('Area de Atuação removida com sucesso', { duration: 3000 })
                        this.dialogApagar = false
                        this.applyFilters(1, true)
                    } else if (response.data.detail) {
                        if (typeof response.data.detail == 'string')
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                    }
                })
            },
            getDominio() {
                this.waitingDominio = true
                DominioValor.findAll({ds_mnemonico: 'TIPO_AREA_ATUACAO'}).then((response) => {
                    this.options.tipo_area_atuacao.push({value: 'NENHUMA', text: 'NENHUMA'})
                    if(response.status === 200) {
                        response.data['TIPO_AREA_ATUACAO'].valores.forEach(item => {
                            this.options.tipo_area_atuacao.push({value: item.ie_valor, text: item.ds_valor})
                        })
                    }
                    this.waitingDominio = false
                })
            },
        }
    }
</script>
